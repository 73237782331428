import { React, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
    PieChart
} from '@mui/x-charts';
import {
    Box, CircularProgress, Grid, IconButton,
    Card, CardHeader, Dialog, DialogTitle, DialogContent
} from '@mui/material';
import CloseIcon from '@mui/icons-material/CloseRounded';
import {
    DataGridPro,
    GridToolbar
} from '@mui/x-data-grid-pro';
import { toast } from 'react-toastify';
import axios from 'axios';
import dayjs from 'dayjs';

const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
}).format;

const numberFormatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
}).format;

const numberFractionFormatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0
}).format;

function shuffle(array) {
    let currentIndex = array.length;

    // While there remain elements to shuffle...
    while (currentIndex != 0) {

        // Pick a remaining element...
        let randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }
}
var colors = [
    'AliceBlue', 'AntiqueWhite', 'Aqua', 'Aquamarine', 'Azure', 'Beige', 'Bisque', 'Black', 'BlanchedAlmond', 'Blue', 'BlueViolet', 'Brown', 'BurlyWood',
    'CadetBlue', 'Chartreuse', 'Chocolate', 'Coral', 'CornflowerBlue', 'Cornsilk', 'Crimson', 'Cyan', 'DarkBlue', 'DarkCyan', 'DarkGoldenRod', 'DarkGray',
    'DarkGreen', 'DarkKhaki', 'DarkMagenta', 'DarkOliveGreen', 'Darkorange', 'DarkOrchid', 'DarkRed', 'DarkSalmon', 'DarkSeaGreen', 'DarkSlateBlue',
    'DarkSlateGray', 'DarkTurquoise', 'DarkViolet', 'DeepPink', 'DeepSkyBlue', 'DimGray', 'DodgerBlue', 'FireBrick', 'FloralWhite', 'ForestGreen',
    'Fuchsia', 'Gainsboro', 'GhostWhite', 'Gold', 'GoldenRod', 'Gray', 'Green', 'GreenYellow', 'HoneyDew', 'HotPink', 'IndianRed ', 'Indigo ', 'Ivory',
    'Khaki', 'Lavender', 'LavenderBlush', 'LawnGreen', 'LemonChiffon', 'LightBlue', 'LightCoral', 'LightCyan', 'LightGoldenRodYellow', 'LightGrey',
    'LightGreen', 'LightPink', 'LightSalmon', 'LightSeaGreen', 'LightSkyBlue', 'LightSlateGray', 'LightSteelBlue', 'LightYellow', 'Lime', 'LimeGreen',
    'Linen', 'Magenta', 'Maroon', 'MediumAquaMarine', 'MediumBlue', 'MediumOrchid', 'MediumPurple', 'MediumSeaGreen', 'MediumSlateBlue',
    'MediumSpringGreen', 'MediumTurquoise', 'MediumVioletRed', 'MidnightBlue', 'MintCream', 'MistyRose', 'Moccasin', 'NavajoWhite', 'Navy', 'OldLace',
    'Olive', 'OliveDrab', 'Orange', 'OrangeRed', 'Orchid', 'PaleGoldenRod', 'PaleGreen', 'PaleTurquoise', 'PaleVioletRed', 'PapayaWhip', 'PeachPuff',
    'Peru', 'Pink', 'Plum', 'PowderBlue', 'Purple', 'Red', 'RosyBrown', 'RoyalBlue', 'SaddleBrown', 'Salmon', 'SandyBrown', 'SeaGreen', 'SeaShell',
    'Sienna', 'Silver', 'SkyBlue', 'SlateBlue', 'SlateGray', 'Snow', 'SpringGreen', 'SteelBlue', 'Tan', 'Teal', 'Thistle', 'Tomato', 'Turquoise', 'Violet',
    'Wheat', 'White', 'WhiteSmoke', 'Yellow', 'YellowGreen'
];

var api = axios.create({ withCredentials: true });

function Details(props) {
    const { getAccessTokenSilently } = useAuth0();
    const [columns, setColumns] = useState(null);
    const [rows, setRows] = useState(null);
    const [pie_data, setPieData] = useState(null);
    const data = props.data;
    const [openDialog, setDialogOpen] = useState(false);
    const [ecolumns, setEColumns] = useState(null);
    const [erows, setERows] = useState(null);
    const [clicked_event, setClickedEvent] = useState('Event Details');

    const handleDialogClickOpen = () => {
        setDialogOpen(true);
    };
    const handleDialogClose = () => {
        setDialogOpen(false);
        setClickedEvent('Event Details');
        setEColumns(null);
        setERows(null);
    };

    const handleRowClick = (params) => {
        getSalesEventData(params.row.event_id);
        setClickedEvent(params.row.col0 + ' @ ' + params.row.col2 + ' | ' + dayjs(params.row.col1).format('MM/DD/YYYY, hh:mmA'));
        handleDialogClickOpen();
    };

    function setGrid() {
        if (data) {
            var c = [
                { field: 'col0', headerName: 'Event', flex: .25, minWidth: 100, type: 'string' },
                { field: 'col1', headerName: 'Date', flex: .25, minWidth: 100, type: 'dateTime' },
                { field: 'col2', headerName: 'Venue', flex: .25, minWidth: 100, type: 'string' },
                {
                    field: 'count', headerName: 'Tickets Sold', flex: .25, minWidth: 100, type: 'number',
                    renderCell: (params) => {
                        return `${numberFormatter(params.value)}`;
                    }
                },
                {
                    field: 'sales', headerName: 'Sales', flex: .25, minWidth: 100, type: 'number',
                    renderCell: (params) => {
                        return `${currencyFormatter(params.value)}`;
                    }
                },
                {
                    field: 'costs', headerName: 'Cost', flex: .25, minWidth: 100, type: 'number',
                    renderCell: (params) => {
                        return `${currencyFormatter(params.value)}`;
                    }
                },
                {
                    field: 'profit', headerName: 'Profit/Loss', flex: .25, minWidth: 100, type: 'number',
                    renderCell: (params) => {
                        return `${currencyFormatter(params.value)}`;
                    }
                },
                {
                    field: 'profit_percent', headerName: 'Profit Margin', flex: .25, minWidth: 100, type: 'number',
                    renderCell: (params) => {
                        return `${numberFormatter(params.value)}%`;
                    }
                }
            ];
            setColumns(c);

            var r = [];
            data.events.forEach((row, i) => {
                r.push({
                    id: i,
                    col0: row._id.event_name,
                    col1: dayjs(row._id.event_datetime.replace(/[Z]$/g, '')).toDate(),
                    col2: row._id.venue_name,
                    sales: row.sales,
                    costs: row.costs,
                    profit: row.profit,
                    profit_percent: row.profit_percent,
                    count: row.count,
                    event_id: row._id.event_id
                });
            });
            setRows(r);

            var p = [];
            var v = 0;
            data.customers.forEach((c, i) => {
                v = v + c[data.sort];
            });
            data.customers.forEach((c, i) => {
                p.push({
                    id: i,
                    value: numberFormatter((c[data.sort] / v) * 100),
                    label: (location) => {
                        if (location === "legend") {
                            return `${c._id} - ${numberFormatter((c[data.sort] / v) * 100)}%`;
                        } else {
                            return <span style={{ fontFamily: "sans-serif" }}>{c._id}</span>;
                        }
                    }
                });
            });
            setPieData(p);
        }
    }

    function getSalesEventData(event_id) {
        getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0AUDIENCE,
            scope: process.env.REACT_APP_AUTH0SCOPE
        }).then((token) => {
            api.get(
                `${process.env.REACT_APP_BASEURL}/api/dashboard/event_sold/${event_id}/${data.buyer_broker_id}/${data.date}`,
                { headers: { Authorization: `Bearer ${token}` } }
            ).then((response) => {
                var c = [
                    { field: 'sale_date', headerName: 'Sale Date', flex: .25, minWidth: 100, type: 'date' },
                    { field: 'customer', headerName: 'Customer', flex: .25, minWidth: 100, type: 'string' },
                    { field: 'count', headerName: 'Sold Qty', flex: .25, minWidth: 50, type: 'number' },
                    { field: 'section', headerName: 'Section', flex: .25, minWidth: 50, type: 'string' },
                    { field: 'row', headerName: 'Row', flex: .25, minWidth: 50, type: 'string' },
                    { field: 'low', headerName: 'Low Seat', flex: .25, minWidth: 50, type: 'number' },
                    { field: 'high', headerName: 'High Seat', flex: .25, minWidth: 50, type: 'number' },
                    {
                        field: 'cost', headerName: 'Cost', flex: .25, minWidth: 100, type: 'number',
                        renderCell: (params) => {
                            return `${currencyFormatter(params.value)}`;
                        }
                    },
                    {
                        field: 'sold', headerName: 'Sold', flex: .25, minWidth: 100, type: 'number',
                        renderCell: (params) => {
                            return `${currencyFormatter(params.value)}`;
                        }
                    },
                    {
                        field: 'avg', headerName: 'Avg Ticket', flex: .25, minWidth: 100, type: 'number',
                        renderCell: (params) => {
                            return `${currencyFormatter(params.value)}`;
                        }
                    },
                    {
                        field: 'profit', headerName: 'Profit/Loss', flex: .25, minWidth: 100, type: 'number',
                        renderCell: (params) => {
                            return `${currencyFormatter(params.value)}`;
                        }
                    },
                    {
                        field: 'profit_percent', headerName: 'Profit Margin', flex: .25, minWidth: 100, type: 'number',
                        renderCell: (params) => {
                            return `${numberFormatter(params.value)}%`;
                        }
                    },
                ];
                setEColumns(c);

                var r = [];
                response.data.forEach((row, i) => {
                    r.push({
                        id: i,
                        sale_date: dayjs(row._id.sale_date.replace(/[Z]$/g, '')).toDate(),
                        customer: row._id.customer,
                        count: row.sold_count,
                        section: row._id.section,
                        row: row._id.row,
                        low: row.low_seat,
                        high: row.high_seat,
                        cost: row.cost,
                        sold: row.sold,
                        avg: row.avg_ticket,
                        profit: row.sold - row.cost,
                        profit_percent: ((row.sold - row.cost) / row.cost) * 100
                    });
                });
                setERows(r);
            }).catch((err) => {
                if (err.message !== 'canceled') {
                    console.log(err);
                }
            });

        });
    }

    useEffect(() => {
        setGrid();
        shuffle(colors);
    }, [data]);

    if (data && columns && rows && pie_data) {
        var header = 'Sales by Customer';
        if (data.sort === 'count') {
            header = 'Tickets Sold by Customer';
        } else if (data.sort === 'costs') {
            header = 'Cost by Customer';
        } else if (data.sort === 'profit') {
            header = 'Profit by Customer';
        }
        return (
            <span style={{ margin: '15px' }}>
                <Dialog
                    open={openDialog}
                    onClose={handleDialogClose}
                    fullWidth
                    maxWidth={'xl'}
                >
                    <DialogTitle>{clicked_event}</DialogTitle>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleDialogClose}
                        aria-label="close"
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent>
                        {
                            erows && ecolumns
                                ?
                                <DataGridPro
                                    slots={{
                                        toolbar: GridToolbar
                                    }}
                                    slotProps={{
                                        toolbar: {
                                            showQuickFilter: true,
                                            quickFilterProps: { debounceMs: 500 }
                                        }
                                    }}
                                    initialState={{
                                        density: "compact"
                                    }}
                                    rows={erows}
                                    columns={ecolumns}
                                    pagination
                                    sx={{ height: '75vh' }}
                                />
                                :
                                <Box
                                    component="main"
                                    sx={{ flexGrow: 1, p: 3, alignItems: 'center', textAlign: 'center' }}
                                >
                                    <CircularProgress size={60} thickness={4} />
                                </Box>
                        }
                    </DialogContent>
                </Dialog>
                <Grid container spacing={2} sx={{ flexGrow: 1 }} alignItems={'center'} alignContent={'center'}>
                    <Grid item xs={12} md={12} lg={7} xl={8}>
                        <Box flexGrow={1} elevation={5}>
                            <Card elevation={8} sx={{ width: '100%', height: '650px' }}>
                                <DataGridPro
                                    slots={{
                                        toolbar: GridToolbar
                                    }}
                                    slotProps={{
                                        toolbar: {
                                            showQuickFilter: true,
                                            quickFilterProps: { debounceMs: 500 }
                                        }
                                    }}
                                    initialState={{
                                        density: "compact"
                                    }}
                                    rows={rows}
                                    columns={columns}
                                    onRowClick={handleRowClick}
                                    pagination
                                />
                            </Card>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={12} lg={5} xl={4}>
                        <Box flexGrow={1}>
                            <Card elevation={5} sx={{ width: '100%', height: '650px' }}>
                                <CardHeader
                                    title={header}
                                />
                                <PieChart
                                    colors={colors}
                                    margin={{ top: 100, left: 100, right: 100, bottom: 50 }}
                                    series={[
                                        {
                                            data: pie_data,
                                            valueFormatter: (value) => {
                                                return (
                                                    <span style={{ fontFamily: "sans-serif" }}>{value.value}%</span>
                                                );
                                            }
                                        }
                                    ]}
                                    slotProps={{
                                        legend: {
                                            direction: 'row',
                                            position: { vertical: 'top', horizontal: 'middle' },
                                        }
                                    }}
                                />
                            </Card>
                        </Box>
                    </Grid>
                </Grid>
            </span >
        );
    } else {
        return (
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, alignItems: 'center', textAlign: 'center' }}
            >
                <CircularProgress size={60} thickness={4} />
            </Box>
        );
    }
}

export default Details;