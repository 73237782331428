import { React, useEffect, useRef, useState, forwardRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
    BarChart
} from '@mui/x-charts';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import {
    Box, CircularProgress, Card, CardHeader, CardContent, CardActionArea, TextField,
    Autocomplete, Table, TableBody, TableCell, TableContainer, TableRow, Backdrop, Grid,
    Button, ButtonGroup, Slide, Dialog, AppBar, Toolbar, IconButton, Typography,
    DialogContent
} from '@mui/material';
import CloseIcon from '@mui/icons-material/CloseRounded';
import {
    DataGridPro,
    GridToolbar
} from '@mui/x-data-grid-pro';
import { toast } from 'react-toastify';
import axios from 'axios';
import dayjs from 'dayjs';

import { socket } from './socket';

import Details from './chart.details';
import Events from './events';

var api = axios.create({ withCredentials: true });

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
}).format;

const numberFormatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
}).format;

const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
};

function Dashboard(props) {
    const { getAccessTokenSilently } = useAuth0();
    const [line_data, setLineData] = useState(null);
    const [broker_event_data, setBrokerEventData] = useState(null);
    const [broker_grid_data, setBrokerGridData] = useState('30d');
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [broker_loading, setBrokerLoading] = useState(true);
    const [event_list, setEventList] = useState(null);
    const [current_event, setCurrentEvent] = useState("all");
    const [eventValue, setEventValue] = useState({ event_id: 'all', event_name: 'Show All', event_date: dayjs().format('YYYY-MM-DD') });
    const [date_range, setDateRange] = useState([dayjs().subtract(30, 'day'), dayjs()]);
    const [quick_date, setQuickDate] = useState('30d');
    const current_broker = props.current_broker;
    const previous_broker = usePrevious(current_broker);
    const isConnected = props.isConnected;
    const [openDialog, setDialogOpen] = useState(false);
    const [event_id, setEventId] = useState(null);
    const [clicked_event, setClickedEvent] = useState('Event Details');
    const [openDayDialog, setDayDialogOpen] = useState(false);
    const [clicked_day, setClickedDay] = useState(dayjs().toDate());
    const [day_data, setDayDialogData] = useState(null);

    const handleDialogClickOpen = () => {
        setDialogOpen(true);
    };
    const handleDialogClose = () => {
        setDialogOpen(false);
        setClickedEvent('Event Details');
    };

    const handleDayDialogClickOpen = () => {
        setDayDialogOpen(true);
    };
    const handleDayDialogClose = () => {
        setDayDialogOpen(false);
        setClickedDay(dayjs().toDate());
        setDayDialogData(null);
    };

    function handleBrokerGrid(val) {
        setBrokerGridData(val);
        setGrid(broker_event_data, val);
    }

    function handleQuickDate(range) {
        if (range === '7d') {
            setQuickDate('7d');
            setDateRange([dayjs().subtract(7, 'day'), dayjs()]);
        } else if (range === '30d') {
            setQuickDate('30d');
            setDateRange([dayjs().subtract(30, 'day'), dayjs()]);
        } else if (range === '60d') {
            setQuickDate('60d');
            setDateRange([dayjs().subtract(60, 'day'), dayjs()]);
        } else if (range === '90d') {
            setQuickDate('90d');
            setDateRange([dayjs().subtract(90, 'day'), dayjs()]);
        } else if (range === '120d') {
            setQuickDate('120d');
            setDateRange([dayjs().subtract(120, 'day'), dayjs()]);
        } else {
            setQuickDate('');
        }
    }

    function getEvents(controller) {
        getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0AUDIENCE,
            scope: process.env.REACT_APP_AUTH0SCOPE
        }).then((token) => {
            api.get(
                `${process.env.REACT_APP_BASEURL}/api/dashboard/events/${current_broker}/${dayjs(date_range[0]).format('YYYY-MM-DD')}/${dayjs(date_range[1]).add(1, 'day').format('YYYY-MM-DD')}`,
                { signal: controller.signal, headers: { Authorization: `Bearer ${token}` } }
            ).then((response) => {
                var tmp = [];
                response.data.forEach((record) => {
                    tmp.push(record._id)
                });
                setEventList(tmp);
            }).catch((err) => {
                if (err.message !== 'canceled') {
                    console.log(err);
                }
            });

        });
    }

    function getSalesData(controller) {
        getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0AUDIENCE,
            scope: process.env.REACT_APP_AUTH0SCOPE
        }).then((token) => {
            api.get(
                `${process.env.REACT_APP_BASEURL}/api/dashboard/sales/${current_event}/${current_broker}/${dayjs(date_range[0]).format('YYYY-MM-DD')}/${dayjs(date_range[1]).add(1, 'day').format('YYYY-MM-DD')}`,
                { signal: controller.signal, headers: { Authorization: `Bearer ${token}` } }
            ).then((response) => {
                var data = response.data;

                var tmp = [];
                data.forEach((row) => {
                    tmp.push({
                        sale_date: dayjs(row.sale_date).toDate(),
                        sales: row.sales,
                        cost: row.cost,
                        profit: row.profit,
                        profit_percent: row.profit_percent,
                        count: row.count
                    });
                });

                setLineData(tmp);
            }).catch((err) => {
                if (err.message !== 'canceled') {
                    console.log(err);
                }
            });

        });
    }

    function getSalesDayData(day, sort) {
        getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0AUDIENCE,
            scope: process.env.REACT_APP_AUTH0SCOPE
        }).then((token) => {
            api.get(
                `${process.env.REACT_APP_BASEURL}/api/dashboard/sales_day/${current_event}/${current_broker}/${day}/${sort}`,
                { headers: { Authorization: `Bearer ${token}` } }
            ).then((response) => {
                setDayDialogData(response.data);
            }).catch((err) => {
                if (err.message !== 'canceled') {
                    console.log(err);
                }
            });

        });
    }

    function getBrokerEventsData(controller) {
        getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0AUDIENCE,
            scope: process.env.REACT_APP_AUTH0SCOPE
        }).then((token) => {
            api.get(
                `${process.env.REACT_APP_BASEURL}/api/dashboard/broker_events/${current_broker}`,
                { signal: controller.signal, headers: { Authorization: `Bearer ${token}` } }
            ).then((response) => {
                var data7 = [];
                var data30 = [];
                var data60 = [];
                var data90 = response.data[0]['90d'];

                data90.forEach((event) => {
                    var diff = dayjs(event._id.event_datetime.replace(/[Z]$/g, '')).diff(dayjs(), 'day');
                    if (diff <= 7) {
                        data7.push(event);
                        data30.push(event);
                        data60.push(event);
                    } else if (diff <= 30) {
                        data30.push(event);
                        data60.push(event);
                    } else if (diff <= 60) {
                        data60.push(event);
                    }
                });

                var data = {
                    '7d': data7,
                    '30d': data30,
                    '60d': data60,
                    '90d': data90
                };

                setGrid(data, broker_grid_data);
                setBrokerEventData(data);
                setBrokerLoading(false);
            }).catch((err) => {
                if (err.message !== 'canceled') {
                    console.log(err);
                    setBrokerLoading(false);
                }
            });

        });
    }

    function setGrid(data, range) {
        var c = [
            { field: 'col0', headerName: 'Event', flex: .25, minWidth: 100, type: 'string' },
            { field: 'col1', headerName: 'Date', flex: .25, minWidth: 100, type: 'dateTime' },
            { field: 'col2', headerName: 'Venue', flex: .25, minWidth: 100, type: 'string' },
            { field: 'col3', headerName: 'Last Sale', flex: .25, minWidth: 100, type: 'dateTime' },
            {
                field: 'col4', headerName: 'Open Cost', flex: .25, minWidth: 100, type: 'number',
                renderCell: (params) => {
                    return `${currencyFormatter(params.value)}`;
                }
            },
            {
                field: 'col5', headerName: 'Potential Sales', flex: .25, minWidth: 100, type: 'number',
                renderCell: (params) => {
                    return `${currencyFormatter(params.value)}`;
                }
            },
            { field: 'col6', headerName: 'Tickets Left', flex: .25, minWidth: 100, type: 'number' }
        ];
        setColumns(c);

        var r = [];
        data[range].forEach((row, i) => {
            r.push({
                id: i,
                col0: row._id.event_name,
                col1: dayjs(row._id.event_datetime.replace(/[Z]$/g, '')).toDate(),
                col2: row._id.venue_name,
                col3: row.hasOwnProperty('sold_tickets') ? dayjs(row.sold_tickets.last_sold.replace(/[Z]$/g, '')).toDate() : null,
                col4: row.cost,
                col5: row.retail_price,
                col6: row.count,
                event_id: row._id.event_id
            });
        });
        setRows(r);
    }

    const handleRowClick = (params) => {
        setEventId(params.row.event_id);
        setClickedEvent(params.row.col0 + ' @ ' + params.row.col2 + ' | ' + dayjs(params.row.col1).format('MM/DD/YYYY, hh:mmA'));
        handleDialogClickOpen();
    };

    useEffect(() => {
        if (previous_broker !== current_broker) {
            setBrokerLoading(true);
            setCurrentEvent('all');
            setEventValue({ event_id: 'all', event_name: 'Show All', event_date: dayjs().format('YYYY-MM-DD') });
        }

        const controller = new AbortController();

        function callAll(controller) {
            getSalesData(controller);
            getBrokerEventsData(controller);
            getEvents(controller);
        }

        if (isConnected) {
            callAll(controller);

            socket.on('data', () => { callAll(controller); });
        }

        return () => {
            socket.off('data');
            controller.abort();
        };
    }, [isConnected, date_range, current_event, current_broker, broker_grid_data]);

    if (line_data !== null && event_list !== null && broker_event_data !== null) {
        var events = event_list;
        if (events.length > 0) {
            if (events[0].event_id !== 'all') {
                events.unshift({ event_id: 'all', event_name: 'Show All', event_date: dayjs().format('YYYY-MM-DD') });
            }
        } else {
            events.unshift({ event_id: 'all', event_name: 'No Sales in Date Range', event_date: dayjs().format('YYYY-MM-DD') });
            setEventValue(events[0]);
        }
        return (
            <>
                <Backdrop
                    sx={{ color: '#fff', zIndex: 10000000 }}
                    open={broker_loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Dialog
                    fullScreen
                    open={openDayDialog}
                    onClose={handleDayDialogClose}
                    TransitionComponent={Transition}
                >
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleDayDialogClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                {dayjs(clicked_day).format('MM/DD/YYYY')}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <Details data={day_data} />
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullScreen
                    open={openDialog}
                    onClose={handleDialogClose}
                    TransitionComponent={Transition}
                >
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleDialogClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                {clicked_event}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <Events isConnected={isConnected} event_id={event_id} current_broker={current_broker} />
                    </DialogContent>
                </Dialog>
                <Grid container spacing={2} sx={{ flexGrow: 1 }} alignItems={'center'} alignContent={'center'}>
                    <Grid item xs={12} md={8} lg={6}>
                        <Grid container spacing={2} sx={{ flexGrow: 1 }} alignItems={'center'} alignContent={'center'}>
                            <Grid item xs={12} md={6} align={'center'}>
                                <Box flexGrow={1}>
                                    <ButtonGroup variant="outlined">
                                        <Button variant={quick_date === '120d' ? 'contained' : 'outlined'} onClick={() => { handleQuickDate('120d') }}>120d</Button>
                                        <Button variant={quick_date === '90d' ? 'contained' : 'outlined'} onClick={() => { handleQuickDate('90d') }}>90d</Button>
                                        <Button variant={quick_date === '60d' ? 'contained' : 'outlined'} onClick={() => { handleQuickDate('60d') }}>60d</Button>
                                        <Button variant={quick_date === '30d' ? 'contained' : 'outlined'} onClick={() => { handleQuickDate('30d') }}>30d</Button>
                                        <Button variant={quick_date === '7d' ? 'contained' : 'outlined'} onClick={() => { handleQuickDate('7d') }}>7d</Button>
                                    </ButtonGroup>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box flexGrow={1}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DateRangePicker
                                            localeText={{ start: 'Date of Sale Start', end: 'Date of Sale End' }}
                                            value={date_range}
                                            onChange={(newValue) => {
                                                // Validate that the range isn't more than 120 days
                                                if (newValue && newValue[0] && newValue[1]) {
                                                    const diffDays = newValue[1].diff(newValue[0], 'day');
                                                    if (diffDays > 120) {
                                                        // Show error toast
                                                        toast.error("Max Range is 120 Days");
                                                        // Don't update the state
                                                        return;
                                                    }
                                                }
                                                setQuickDate('');
                                                setDateRange(newValue);
                                            }}
                                            slotProps={{
                                                field: {
                                                    readOnly: false // Explicitly allow typing
                                                },
                                                textField: {
                                                    inputProps: {
                                                        autoComplete: 'off' // Prevents browser autocomplete
                                                    }
                                                }
                                            }}
                                        />
                                    </LocalizationProvider>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={4} lg={6}>
                        <Box flexGrow={1}>
                            <Autocomplete
                                options={events}
                                getOptionLabel={(option) => option.event_date + " | " + option.event_name}
                                getOptionKey={(option) => option.event_id}
                                defaultValue={events[0]}
                                disableClearable={true}
                                value={eventValue}
                                onChange={(event, newValue) => {
                                    setEventValue(newValue);
                                    setCurrentEvent(newValue.event_id);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Event Search"
                                    />
                                )}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <br />
                <Grid container spacing={2} sx={{ flexGrow: 1 }} alignItems={'center'} alignContent={'center'}>
                    <Grid item xs={12} md={6} lg={2.4}>
                        <Box flexGrow={1}>
                            <Card elevation={5}>
                                <CardHeader
                                    title={currencyFormatter(line_data.reduce((n, { sales }) => n + sales, 0))}
                                    subheader='Total Sales'
                                    titleTypographyProps={{ color: process.env.REACT_APP_COLOR1 }}
                                    subheaderTypographyProps={{}}
                                    sx={{ background: 'transparent' }}
                                />
                                <CardContent>
                                    <BarChart
                                        borderRadius={10}
                                        height={200}
                                        margin={{
                                            left: 0,
                                            top: 0,
                                            bottom: 0,
                                            right: 0
                                        }}
                                        xAxis={[
                                            {
                                                dataKey: 'sale_date',
                                                scaleType: 'band',
                                                min: line_data.length > 0 ? line_data[0].sale_date : dayjs().toDate(),
                                                max: line_data.length > 0 ? line_data[line_data.length - 1].sale_date : dayjs().toDate(),
                                                valueFormatter: (value) => { return dayjs(value).format('MM/DD/YY'); }
                                            },
                                        ]}
                                        yAxis={[
                                            {
                                                max: Math.max(...line_data.map(o => o.sales)) + (Math.max(...line_data.map(o => o.sales)) * .1),
                                                valueFormatter: (value) => (currencyFormatter(value))
                                            }
                                        ]}
                                        leftAxis={null}
                                        bottomAxis={null}
                                        series={[
                                            {
                                                dataKey: 'sales',
                                                color: '#41A949',
                                                area: true,
                                                showMark: false,
                                                valueFormatter: (value) => (currencyFormatter(value))
                                            }
                                        ]}
                                        dataset={line_data}
                                        onItemClick={(event, d) => {
                                            setClickedDay(line_data[d.dataIndex].sale_date);
                                            getSalesDayData(dayjs(line_data[d.dataIndex].sale_date).format('YYYY-MM-DD'), 'sales');
                                            handleDayDialogClickOpen();
                                        }}
                                        onAxisClick={(event, d) => {
                                            setClickedDay(d.axisValue);
                                            getSalesDayData(dayjs(d.axisValue).format('YYYY-MM-DD'), 'profit');
                                            handleDayDialogClickOpen();
                                        }}
                                    />
                                </CardContent>
                            </Card>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={2.4}>
                        <Box flexGrow={1}>
                            <Card elevation={5}>
                                <CardHeader
                                    title={numberFormatter(line_data.reduce((n, { count }) => n + count, 0))}
                                    subheader='Total Sold Tickets'
                                    titleTypographyProps={{ color: process.env.REACT_APP_COLOR1 }}
                                    subheaderTypographyProps={{}}
                                    sx={{ background: 'transparent' }}
                                />
                                <CardContent>
                                    <BarChart
                                        borderRadius={10}
                                        height={200}
                                        margin={{
                                            left: 0,
                                            top: 0,
                                            bottom: 0,
                                            right: 0
                                        }}
                                        xAxis={[
                                            {
                                                dataKey: 'sale_date',
                                                scaleType: 'band',
                                                min: line_data.length > 0 ? line_data[0].sale_date : dayjs().toDate(),
                                                max: line_data.length > 0 ? line_data[line_data.length - 1].sale_date : dayjs().toDate(),
                                                valueFormatter: (value) => { return dayjs(value).format('MM/DD/YY'); }
                                            },
                                        ]}
                                        yAxis={[
                                            {
                                                max: Math.max(...line_data.map(o => o.count)) + (Math.max(...line_data.map(o => o.count)) * .1),
                                                valueFormatter: (value) => (numberFormatter(value))
                                            }
                                        ]}
                                        leftAxis={null}
                                        bottomAxis={null}
                                        series={[
                                            {
                                                dataKey: 'count',
                                                color: '#41A949',
                                                area: true,
                                                showMark: false,
                                                valueFormatter: (value) => (numberFormatter(value))
                                            }
                                        ]}
                                        dataset={line_data}
                                        onItemClick={(event, d) => {
                                            setClickedDay(line_data[d.dataIndex].sale_date);
                                            getSalesDayData(dayjs(line_data[d.dataIndex].sale_date).format('YYYY-MM-DD'), 'sales');
                                            handleDayDialogClickOpen();
                                        }}
                                        onAxisClick={(event, d) => {
                                            setClickedDay(d.axisValue);
                                            getSalesDayData(dayjs(d.axisValue).format('YYYY-MM-DD'), 'profit');
                                            handleDayDialogClickOpen();
                                        }}
                                    />
                                </CardContent>
                            </Card>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4} lg={2.4}>
                        <Box flexGrow={1}>
                            <Card elevation={5}>
                                <CardHeader
                                    title={currencyFormatter(line_data.reduce((n, { cost }) => n + cost, 0))}
                                    subheader='Total Cost'
                                    titleTypographyProps={{ color: process.env.REACT_APP_COLOR1 }}
                                    subheaderTypographyProps={{}}
                                    sx={{ background: 'transparent' }}
                                />
                                <CardContent>
                                    <BarChart
                                        borderRadius={10}
                                        height={200}
                                        margin={{
                                            left: 0,
                                            top: 0,
                                            bottom: 0,
                                            right: 0
                                        }}
                                        xAxis={[
                                            {
                                                dataKey: 'sale_date',
                                                scaleType: 'band',
                                                min: line_data.length > 0 ? line_data[0].sale_date : dayjs().toDate(),
                                                max: line_data.length > 0 ? line_data[line_data.length - 1].sale_date : dayjs().toDate(),
                                                valueFormatter: (value) => { return dayjs(value).format('MM/DD/YY'); }
                                            },
                                        ]}
                                        yAxis={[
                                            {
                                                max: Math.max(...line_data.map(o => o.cost)) + (Math.max(...line_data.map(o => o.cost)) * .1),
                                                valueFormatter: (value) => (currencyFormatter(value))
                                            }
                                        ]}
                                        leftAxis={null}
                                        bottomAxis={null}
                                        series={[
                                            {
                                                dataKey: 'cost',
                                                color: '#41A949',
                                                area: true,
                                                showMark: false,
                                                valueFormatter: (value) => (currencyFormatter(value))
                                            }
                                        ]}
                                        dataset={line_data}
                                        onItemClick={(event, d) => {
                                            setClickedDay(line_data[d.dataIndex].sale_date);
                                            getSalesDayData(dayjs(line_data[d.dataIndex].sale_date).format('YYYY-MM-DD'), 'sales');
                                            handleDayDialogClickOpen();
                                        }}
                                        onAxisClick={(event, d) => {
                                            setClickedDay(d.axisValue);
                                            getSalesDayData(dayjs(d.axisValue).format('YYYY-MM-DD'), 'profit');
                                            handleDayDialogClickOpen();
                                        }}
                                    />
                                </CardContent>
                            </Card>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4} lg={2.4}>
                        <Box flexGrow={1}>
                            <Card elevation={5}>
                                <CardHeader
                                    title={currencyFormatter(line_data.reduce((n, { profit }) => n + profit, 0))}
                                    subheader='Total Profit'
                                    titleTypographyProps={{ color: process.env.REACT_APP_COLOR1 }}
                                    subheaderTypographyProps={{}}
                                    sx={{ background: 'transparent' }}
                                />
                                <CardContent>
                                    <BarChart
                                        borderRadius={10}
                                        height={200}
                                        margin={{
                                            left: 0,
                                            top: 0,
                                            bottom: 0,
                                            right: 0
                                        }}
                                        xAxis={[
                                            {
                                                dataKey: 'sale_date',
                                                scaleType: 'band',
                                                min: line_data.length > 0 ? line_data[0].sale_date : dayjs().toDate(),
                                                max: line_data.length > 0 ? line_data[line_data.length - 1].sale_date : dayjs().toDate(),
                                                valueFormatter: (value) => { return dayjs(value).format('MM/DD/YY'); }
                                            },
                                        ]}
                                        yAxis={[
                                            {
                                                max: Math.max(...line_data.map(o => o.profit)) + (Math.max(...line_data.map(o => o.profit)) * .1),
                                                min: Math.min(...line_data.map(o => o.profit)) + (Math.min(...line_data.map(o => o.profit)) * .1),
                                                valueFormatter: (value) => (currencyFormatter(value))
                                            }
                                        ]}
                                        leftAxis={null}
                                        bottomAxis={null}
                                        series={[
                                            {
                                                dataKey: 'profit',
                                                color: '#41A949',
                                                area: true,
                                                showMark: false,
                                                valueFormatter: (value) => (currencyFormatter(value))
                                            }
                                        ]}
                                        dataset={line_data}
                                        onItemClick={(event, d) => {
                                            setClickedDay(line_data[d.dataIndex].sale_date);
                                            getSalesDayData(dayjs(line_data[d.dataIndex].sale_date).format('YYYY-MM-DD'), 'sales');
                                            handleDayDialogClickOpen();
                                        }}
                                        onAxisClick={(event, d) => {
                                            setClickedDay(d.axisValue);
                                            getSalesDayData(dayjs(d.axisValue).format('YYYY-MM-DD'), 'profit');
                                            handleDayDialogClickOpen();
                                        }}
                                    />
                                </CardContent>
                            </Card>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4} lg={2.4}>
                        <Box flexGrow={1}>
                            <Card elevation={5}>
                                <CardHeader
                                    title={
                                        line_data.length > 0
                                            ?
                                            Math.round((line_data.reduce((n, { profit }) => n + profit, 0) / line_data.reduce((n, { sales }) => n + sales, 0)) * 100) + '%'
                                            :
                                            '0%'
                                    }
                                    subheader='Profit Margin'
                                    titleTypographyProps={{ color: process.env.REACT_APP_COLOR1 }}
                                    subheaderTypographyProps={{}}
                                    sx={{ background: 'transparent' }}
                                />
                                <CardContent>
                                    <BarChart
                                        borderRadius={10}
                                        height={200}
                                        margin={{
                                            left: 0,
                                            top: 0,
                                            bottom: 0,
                                            right: 0
                                        }}
                                        xAxis={[
                                            {
                                                dataKey: 'sale_date',
                                                scaleType: 'band',
                                                min: line_data.length > 0 ? line_data[0].sale_date : dayjs().toDate(),
                                                max: line_data.length > 0 ? line_data[line_data.length - 1].sale_date : dayjs().toDate(),
                                                valueFormatter: (value) => { return dayjs(value).format('MM/DD/YY'); }
                                            },
                                        ]}
                                        yAxis={[
                                            {
                                                max: Math.max(...line_data.map(o => o.profit_percent)) + (Math.max(...line_data.map(o => o.profit_percent)) * .1),
                                                min: Math.min(...line_data.map(o => o.profit_percent)) + (Math.min(...line_data.map(o => o.profit_percent)) * .1),
                                                valueFormatter: (value) => { return `${Math.round(value)}%`; }
                                            }
                                        ]}
                                        leftAxis={null}
                                        bottomAxis={null}
                                        series={[
                                            {
                                                dataKey: 'profit_percent',
                                                color: '#41A949',
                                                area: true,
                                                showMark: false,
                                                valueFormatter: (value) => { return `${Math.round(value)}%`; }
                                            }
                                        ]}
                                        dataset={line_data}
                                        onItemClick={(event, d) => {
                                            setClickedDay(line_data[d.dataIndex].sale_date);
                                            getSalesDayData(dayjs(line_data[d.dataIndex].sale_date).format('YYYY-MM-DD'), 'sales');
                                            handleDayDialogClickOpen();
                                        }}
                                        onAxisClick={(event, d) => {
                                            setClickedDay(d.axisValue);
                                            getSalesDayData(dayjs(d.axisValue).format('YYYY-MM-DD'), 'profit');
                                            handleDayDialogClickOpen();
                                        }}
                                    />
                                </CardContent>
                            </Card>
                        </Box>
                    </Grid>
                </Grid>
                <br /><br />
                <Grid container spacing={2} sx={{ flexGrow: 1 }} alignItems={'center'} alignContent={'center'}>
                    <Grid item xs={12} md={6} lg={3}>
                        <Box flexGrow={1}>
                            <Card variant="outlined" elevation={1}
                                sx={
                                    broker_grid_data === '7d' ? {
                                        boxShadow: 5,
                                        border: 5,
                                        borderColor: 'rgba(25,118,210,0.75)'
                                    } : {}
                                }
                            >
                                <CardActionArea onClick={() => { handleBrokerGrid('7d'); }} disabled={broker_grid_data === '7d'}>
                                    <CardHeader
                                        title='Next 7 Day Inventory'
                                        subheader={`${broker_event_data['7d'].length} Events`}
                                        titleTypographyProps={broker_grid_data === '7d' ? { color: 'white' } : {}}
                                        subheaderTypographyProps={broker_grid_data === '7d' ? { color: 'white' } : {}}
                                        sx={broker_grid_data === '7d' ? { backgroundColor: 'rgba(25,118,210,0.75)' } : { backgroundColor: 'gainsboro' }}
                                    />
                                    <CardContent>
                                        <TableContainer component={Box}>
                                            <Table size="small">
                                                <TableBody>
                                                    <TableRow key={'tickets'}>
                                                        <TableCell component="th" scope="row">Tickets Left</TableCell>
                                                        <TableCell align="right">{numberFormatter(broker_event_data['7d'].reduce((n, { count }) => n + count, 0))}</TableCell>
                                                    </TableRow>
                                                    <TableRow key={'cost'}>
                                                        <TableCell component="th" scope="row">Open Cost</TableCell>
                                                        <TableCell align="right">{currencyFormatter(broker_event_data['7d'].reduce((n, { cost }) => n + cost, 0))}</TableCell>
                                                    </TableRow>
                                                    <TableRow key={'sales'}>
                                                        <TableCell component="th" scope="row">Potential Sales</TableCell>
                                                        <TableCell align="right">{currencyFormatter(broker_event_data['7d'].reduce((n, { retail_price }) => n + retail_price, 0))}</TableCell>
                                                    </TableRow>
                                                    <TableRow key={'profit'}>
                                                        <TableCell component="th" scope="row">Potential Profit</TableCell>
                                                        <TableCell align="right">{currencyFormatter(broker_event_data['7d'].reduce((n, { expected_profit }) => n + expected_profit, 0))}</TableCell>
                                                    </TableRow>
                                                    <TableRow key={'profit_percent'}>
                                                        <TableCell component="th" scope="row">Potential Profit %</TableCell>
                                                        <TableCell align="right">{
                                                            broker_event_data['7d'].reduce((n, { retail_price }) => n + retail_price, 0) > 0
                                                                ?
                                                                Math.round((broker_event_data['7d'].reduce((n, { expected_profit }) => n + expected_profit, 0) / broker_event_data['7d'].reduce((n, { retail_price }) => n + retail_price, 0)) * 100) + '%'
                                                                :
                                                                '0%'
                                                        }</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <Box flexGrow={1}>
                            <Card variant="outlined" elevation={1}
                                sx={
                                    broker_grid_data === '30d' ? {
                                        boxShadow: 5,
                                        border: 5,
                                        borderColor: 'rgba(25,118,210,0.75)'
                                    } : {}
                                }
                            >
                                <CardActionArea onClick={() => { handleBrokerGrid('30d'); }} disabled={broker_grid_data === '30d'}>
                                    <CardHeader
                                        title='Next 30 Day Inventory'
                                        subheader={`${broker_event_data['30d'].length} Events`}
                                        titleTypographyProps={broker_grid_data === '30d' ? { color: 'white' } : {}}
                                        subheaderTypographyProps={broker_grid_data === '30d' ? { color: 'white' } : {}}
                                        sx={broker_grid_data === '30d' ? { backgroundColor: 'rgba(25,118,210,0.75)' } : { backgroundColor: 'gainsboro' }}
                                    />
                                    <CardContent>
                                        <TableContainer component={Box}>
                                            <Table size="small">
                                                <TableBody>
                                                    <TableRow key={'tickets'}>
                                                        <TableCell component="th" scope="row">Tickets Left</TableCell>
                                                        <TableCell align="right">{numberFormatter(broker_event_data['30d'].reduce((n, { count }) => n + count, 0))}</TableCell>
                                                    </TableRow>
                                                    <TableRow key={'cost'}>
                                                        <TableCell component="th" scope="row">Open Cost</TableCell>
                                                        <TableCell align="right">{currencyFormatter(broker_event_data['30d'].reduce((n, { cost }) => n + cost, 0))}</TableCell>
                                                    </TableRow>
                                                    <TableRow key={'sales'}>
                                                        <TableCell component="th" scope="row">Potential Sales</TableCell>
                                                        <TableCell align="right">{currencyFormatter(broker_event_data['30d'].reduce((n, { retail_price }) => n + retail_price, 0))}</TableCell>
                                                    </TableRow>
                                                    <TableRow key={'profit'}>
                                                        <TableCell component="th" scope="row">Potential Profit</TableCell>
                                                        <TableCell align="right">{currencyFormatter(broker_event_data['30d'].reduce((n, { expected_profit }) => n + expected_profit, 0))}</TableCell>
                                                    </TableRow>
                                                    <TableRow key={'profit_percent'}>
                                                        <TableCell component="th" scope="row">Potential Profit %</TableCell>
                                                        <TableCell align="right">{
                                                            broker_event_data['30d'].reduce((n, { retail_price }) => n + retail_price, 0) > 0
                                                                ?
                                                                Math.round((broker_event_data['30d'].reduce((n, { expected_profit }) => n + expected_profit, 0) / broker_event_data['30d'].reduce((n, { retail_price }) => n + retail_price, 0)) * 100) + '%'
                                                                :
                                                                '0%'
                                                        }</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <Box flexGrow={1}>
                            <Card variant="outlined" elevation={1}
                                sx={
                                    broker_grid_data === '60d' ? {
                                        boxShadow: 5,
                                        border: 5,
                                        borderColor: 'rgba(25,118,210,0.75)'
                                    } : {}
                                }
                            >
                                <CardActionArea onClick={() => { handleBrokerGrid('60d'); }} disabled={broker_grid_data === '60d'}>
                                    <CardHeader
                                        title='Next 60 Day Inventory'
                                        subheader={`${broker_event_data['60d'].length} Events`}
                                        titleTypographyProps={broker_grid_data === '60d' ? { color: 'white' } : {}}
                                        subheaderTypographyProps={broker_grid_data === '60d' ? { color: 'white' } : {}}
                                        sx={broker_grid_data === '60d' ? { backgroundColor: 'rgba(25,118,210,0.75)' } : { backgroundColor: 'gainsboro' }}
                                    />
                                    <CardContent>
                                        <TableContainer component={Box}>
                                            <Table size="small">
                                                <TableBody>
                                                    <TableRow key={'tickets'}>
                                                        <TableCell component="th" scope="row">Tickets Left</TableCell>
                                                        <TableCell align="right">{numberFormatter(broker_event_data['60d'].reduce((n, { count }) => n + count, 0))}</TableCell>
                                                    </TableRow>
                                                    <TableRow key={'cost'}>
                                                        <TableCell component="th" scope="row">Open Cost</TableCell>
                                                        <TableCell align="right">{currencyFormatter(broker_event_data['60d'].reduce((n, { cost }) => n + cost, 0))}</TableCell>
                                                    </TableRow>
                                                    <TableRow key={'sales'}>
                                                        <TableCell component="th" scope="row">Potential Sales</TableCell>
                                                        <TableCell align="right">{currencyFormatter(broker_event_data['60d'].reduce((n, { retail_price }) => n + retail_price, 0))}</TableCell>
                                                    </TableRow>
                                                    <TableRow key={'profit'}>
                                                        <TableCell component="th" scope="row">Potential Profit</TableCell>
                                                        <TableCell align="right">{currencyFormatter(broker_event_data['60d'].reduce((n, { expected_profit }) => n + expected_profit, 0))}</TableCell>
                                                    </TableRow>
                                                    <TableRow key={'profit_percent'}>
                                                        <TableCell component="th" scope="row">Potential Profit %</TableCell>
                                                        <TableCell align="right">{
                                                            broker_event_data['60d'].reduce((n, { retail_price }) => n + retail_price, 0) > 0
                                                                ?
                                                                Math.round((broker_event_data['60d'].reduce((n, { expected_profit }) => n + expected_profit, 0) / broker_event_data['60d'].reduce((n, { retail_price }) => n + retail_price, 0)) * 100) + '%'
                                                                :
                                                                '0%'
                                                        }</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <Box flexGrow={1}>
                            <Card variant="outlined" elevation={1}
                                sx={
                                    broker_grid_data === '90d' ? {
                                        boxShadow: 5,
                                        border: 5,
                                        borderColor: 'rgba(25,118,210,0.75)'
                                    } : {}
                                }
                            >
                                <CardActionArea onClick={() => { handleBrokerGrid('90d'); }} disabled={broker_grid_data === '90d'}>
                                    <CardHeader
                                        title='Next 90 Day Inventory'
                                        subheader={`${broker_event_data['90d'].length} Events`}
                                        titleTypographyProps={broker_grid_data === '90d' ? { color: 'white' } : {}}
                                        subheaderTypographyProps={broker_grid_data === '90d' ? { color: 'white' } : {}}
                                        sx={broker_grid_data === '90d' ? { backgroundColor: 'rgba(25,118,210,0.75)' } : { backgroundColor: 'gainsboro' }}
                                    />
                                    <CardContent>
                                        <TableContainer component={Box}>
                                            <Table size="small">
                                                <TableBody>
                                                    <TableRow key={'tickets'}>
                                                        <TableCell component="th" scope="row">Tickets Left</TableCell>
                                                        <TableCell align="right">{numberFormatter(broker_event_data['90d'].reduce((n, { count }) => n + count, 0))}</TableCell>
                                                    </TableRow>
                                                    <TableRow key={'cost'}>
                                                        <TableCell component="th" scope="row">Open Cost</TableCell>
                                                        <TableCell align="right">{currencyFormatter(broker_event_data['90d'].reduce((n, { cost }) => n + cost, 0))}</TableCell>
                                                    </TableRow>
                                                    <TableRow key={'sales'}>
                                                        <TableCell component="th" scope="row">Potential Sales</TableCell>
                                                        <TableCell align="right">{currencyFormatter(broker_event_data['90d'].reduce((n, { retail_price }) => n + retail_price, 0))}</TableCell>
                                                    </TableRow>
                                                    <TableRow key={'profit'}>
                                                        <TableCell component="th" scope="row">Potential Profit</TableCell>
                                                        <TableCell align="right">{currencyFormatter(broker_event_data['90d'].reduce((n, { expected_profit }) => n + expected_profit, 0))}</TableCell>
                                                    </TableRow>
                                                    <TableRow key={'profit_percent'}>
                                                        <TableCell component="th" scope="row">Potential Profit %</TableCell>
                                                        <TableCell align="right">{
                                                            broker_event_data['30d'].reduce((n, { retail_price }) => n + retail_price, 0) > 0
                                                                ?
                                                                Math.round((broker_event_data['90d'].reduce((n, { expected_profit }) => n + expected_profit, 0) / broker_event_data['90d'].reduce((n, { retail_price }) => n + retail_price, 0)) * 100) + '%'
                                                                :
                                                                '0%'
                                                        }</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Box>
                    </Grid>
                </Grid>
                <br />
                <Box flexGrow={1}>
                    <div style={{ width: '100%' }}>
                        <DataGridPro
                            autoHeight
                            rows={rows}
                            columns={columns}
                            onRowClick={handleRowClick}
                            pagination
                            initialState={{
                                pagination: {
                                    paginationModel: { pageSize: 10, page: 0 },
                                },
                                density: "compact"
                            }}
                            pageSizeOptions={[10]}
                            sx={{
                                boxShadow: 5,
                                border: 5,
                                borderColor: 'rgba(25,118,210,0.75)'
                            }}
                            slots={{
                                toolbar: GridToolbar
                            }}
                            slotProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    quickFilterProps: { debounceMs: 500 }
                                }
                            }}
                        />
                    </div>
                </Box>
            </>
        );
    } else {
        return (
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, alignItems: 'center', textAlign: 'center' }}
            >
                <CircularProgress size={60} thickness={4} />
            </Box>
        );
    }
}

export default Dashboard;